body {
  background-color: rgb(36, 41, 46);
  color: white;
  font-family: Verdana, Geneva, sans-serif;
}

.App {
  display: block;
  line-height: 1.25;
}

.App h1 {
  color: rgb(255, 111, 97);
}

.App hr {
  border-color: #b5bab6;
}
